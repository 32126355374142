.button {
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  background-color: $color-grey;
  box-shadow: $border-raised-outer, $border-raised-inner;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $color-black;
  padding: 0.5rem 0.75rem;

  &:active {
    box-shadow: $border-sunken-outer, $border-sunken-inner;
  }

  &--close {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0h2v1h1v1h2V1h1V0h2v1H7v1H6v1H5v1h1v1h1v1h1v1H6V6H5V5H3v1H2v1H0V6h1V5h1V4h1V3H2V2H1V1H0V0z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
  }
}
