.window {
  touch-action: none;
  display: none;
  position: absolute !important;
  top: 5vh;
  left: 5vh;
  width: calc(100% - 5vh * 2);
  max-width: calc(100% - 5vw * 2);
  max-height: calc(100% - 5vh * 2 - 50px);
  height: 400px;
  padding: 3px;
  background: $color-grey;
  box-shadow: $border-window-inner, $border-window-outer;
  overflow: hidden;

  transition: opacity 150ms ease, filter 150ms ease;

  @media (min-width: 1024px) {
    width: 50vw;
  }

  &:nth-child(2) {
    top: 6vh;
    left: 6vh;
  }
  &:nth-child(3) {
    top: 7vh;
    left: 7vh;
  }
  &:nth-child(4) {
    top: 8vh;
    left: 8vh;
  }

  &--active {
    z-index: 999;
  }
  &--dragged {
    opacity: 0.9;
    filter: grayscale(0.5);
  }

  &__inner {
    position: absolute;
    top: 2px;
    left: 1px;
    height: calc(100% - 38px - 4px * 2);
    width: calc(100% - 2px);
    background: transparent;
    user-select: text;
    overflow-y: scroll;
    overflow-x: hidden;

    &--white {
      background: $color-white;
    }
  }

  &__content {
    padding: 1.75rem 3.25rem 1.75rem 1.75rem;
  }
}
