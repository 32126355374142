.overlay {
  position: fixed;
  top: -100vh;
  left: 0;
  background: repeating-linear-gradient(
    rgba($color-white, 0.05) 0,
    transparent 1px,
    transparent 3px,
    rgba($color-white, 0.05) 4px
  );
  width: 100vw;
  height: 200vh;
  pointer-events: none;
  z-index: 999;
  animation: buzz 7500ms alternate linear infinite;
  mix-blend-mode: hard-light;
}

@keyframes buzz {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
}
