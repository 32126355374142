*::-webkit-scrollbar {
  display: none;
  background-color: transparent;
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: transparent;
}
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.simplebar-track.simplebar-vertical {
  background-color: $color-grey-light;
  width: 21px !important;
  box-shadow: $border-sunken-outer, $border-sunken-inner;
}
.simplebar-scrollbar {
  width: 18px !important;
  margin: 2px auto 4px;
  background-color: $color-grey;
  box-shadow: $border-window-outer, $border-window-inner;
}

.simplebar-content {
  height: 100%;
}
