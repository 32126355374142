@import "normalize.css/normalize.css";
@import "simplebar/dist/simplebar.css";
@import "variables";
@import "fonts";
@import "typography";
@import "button";
@import "window";
@import "scrollbar";
@import "title-bar";
@import "icon";
@import "screen-animation";

*,
*:after,
*:before {
  box-sizing: border-box;
  image-rendering: crisp-edges;
}

.screen-wrapper {
  position: relative;
  user-select: none;
  background-color: $color-primary;
  height: 100vh;
  max-height: -moz-fill-available;
  max-height: -webkit-fill-available;
}

.desktop {
  background-image: url(../images/logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
  max-height: -moz-fill-available;
  max-height: -webkit-fill-available;
  @media screen and (min-width: 309px) {
    background-size: auto;
  }
}

.bottom-bar {
  position: absolute;
  bottom: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-grey;
  border-top: 1px solid $color-grey-light;
  outline: 1px solid $color-grey-dark;
  padding: 0.25rem;

  .status {
    display: flex;
    align-items: center;

    .sound {
      cursor: pointer;
    }

    #time {
      padding: 0.5rem;
    }
  }
}

.start-menu {
  position: absolute;
  bottom: 40px;
  left: 4px;
  list-style: none;
  background-color: $color-grey;
  margin: 0;
  padding: 0;
  z-index: 800;
  box-shadow: $border-window-inner, $border-window-outer;
  display: none;
  a {
    color: $color-black;
    display: block;
    padding: 0.5rem 1rem;
    margin: 0 2px;
    border-bottom: 2px solid rgba($color-black, 0.15);
    &:first-child {
      margin-top: 2px;
    }
    &:hover {
      background-color: $color-primary;
      color: #fff;
    }
    &:active {
      outline: 1px dotted $color-black;
    }
  }
}

input#start-menu {
  display: none;
  &:checked + .start-menu {
    display: block;
  }
}

#time {
  margin-right: 0.5rem;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  font-size: 0.8em;
  width: 8em;
  height: 8em;
  margin: 0.5em;
  border: 1px dotted transparent;
  > p {
    margin: 0;
  }

  &.icon--active {
    background: darken($color-primary, 2.5%);
    border-color: lighten($color-primary, 60%);
  }
}
.icon {
  & > img {
  }
}

.content-title {
  margin-bottom: 1rem;
}

.service-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  .service {
    padding: 1rem;
    min-width: 320px;
    width: 50%;
    .service-image {
      margin-bottom: 1rem;
    }
    .service-copy {
      ul {
        list-style: url(../images/checkmark.png) outside;
        padding-left: 1.25rem;
        li {
          margin: 0.5em 0;
        }
      }
    }
  }
}
