body {
  font-family: "Pixelated MS Sans Serif";
}

h1,
h2,
h3,
p.lead {
  color: $color-primary;
  margin-bottom: 0.5em;
}

h1 {
  font-size: 1.6em;
}

h2 {
  font-size: 1.4em;
}

p {
  & + p {
    margin-top: 1em;
  }
  &.lead {
    font-size: 1.2em;
  }
}

a {
  text-decoration: none;
  color: $color-primary;
  cursor: default;
}
