$color-primary: #004264;
$color-primary-light: lighten($color-primary, 10%);
$color-grey-light: #dfdfdf;
$color-grey: #c0c0c0;
$color-grey-dark: #808080;
$color-white: #ffffff;
$color-black: #121212;

// Borders
$border-raised-outer: inset -1px -1px $color-black, inset 1px 1px $color-white;
$border-raised-inner: inset -2px -2px $color-grey-dark,
  inset 2px 2px $color-grey-light;
$border-sunken-outer: inset -1px -1px $color-white, inset 1px 1px $color-black;
$border-sunken-inner: inset -2px -2px $color-grey-light,
  inset 2px 2px $color-grey-dark;

$border-window-outer: inset -1px -1px $color-grey-dark,
  inset 1px 1px $color-grey-light;
$border-window-inner: inset -2px -2px $color-black, inset 2px 2px $color-white;
