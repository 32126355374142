.title-bar {
  background: linear-gradient(to right, $color-primary, $color-primary-light);
  color: $color-white;
  display: flex;
  align-items: center;
  padding: 2px;
  padding-left: 4px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &__icon {
    @extend .icon--prefix;
  }

  &__text {
    flex: 1 0 auto;
  }

  &__controls {
    button {
      padding: 0;
      display: block;
      width: 20px;
      height: 20px;
    }
  }
}
